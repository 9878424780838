import { useEffect } from "react";
import { Link } from "react-router-dom";
import Swiper from "swiper";
import 'swiper/swiper-bundle.css';



function Projets()
{
    useEffect( () => {
        const handleSwiper = () => {
            const swiper = new Swiper(".application-slider",{
                slidesPerView: 5,
                centeredSlides: true,
                loop: true,
                spaceBetween: 20,
                breakpoints: {
                    0: {
                        slidesPerView: 1
                    },
                    576: {
                        slidesPerView: 2
                    },
                    768: {
                        slidesPerView: 3
                    },
                    992: {
                        slidesPerView: 4
                    },
                    1200: {
                        slidesPerView: 5
                    }
                }
            });
            return ()=>{
                swiper.destroy();
            }
        };
    },[]);
    
    return (
        <div className="stand-warp">
        <div className="container">
            <div className="inner-stand-warp d-flex align-items-center justify-content-between">
                <div className="content">
                    <span className="title">Support client</span>
                    <h2>Chez CSI, nous croyons en la qualité, l'innovation et le service client exceptionnel.</h2>
                </div>
                <div className="stand-btn">
                    <Link to="#" className="default-btn white-color">Contact Nous Maintenant</Link>
                </div>
            </div>
        </div>
    </div>
    );
}
export default Projets