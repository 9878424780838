import { Link } from "react-router-dom";
function About()
{
    return (
        <div className="hero-style1-warp">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="hero-style1-content">
                            <span className="title">Notre Entreprise</span>
                            <h1>
                                <img src="assets/images/shapes/content-shape1.png" className="content-shape1" alt="" />
                                Notre Engagement envers la Qualité et l'Innovation
                            </h1>
                            <p>Fondée avec une passion pour l'excellence technologique, La Compagnie de solutions Informatiques (CSI) est une entreprise dynamique spécialisée dans la fourniture de solutions informatiques intégrées. Forts d'une équipe talentueuse d'experts en informatique, nous sommes déterminés à apporter une valeur ajoutée à votre entreprise grâce à notre expertise approfondie.</p>
                            <div className="hero-style1-btn d-flex align-items-center">
                                <Link to="account.html" className="default-btn">En savoir Plus</Link>
                                <Link to="services.html" className="hero-service-btn">
                                    <i className="flaticon-trend"></i>
                                    Tous nos services
                                </Link>
                            </div>
                            <ul className="hero-style1-list">
                                <li>
                                    <i className="flaticon-pointing-to-right"></i>
                                    Expertise approfondie,
                                </li>
                                <li>
                                    <i className="flaticon-pointing-to-right"></i>
                                    Eavoir-faire technologique,
                                </li>
                                <li>
                                    <i className="flaticon-pointing-to-right"></i>
                                    développement de marque
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="hero-style1-image">
                            <img src="assets/images/heros/hero1.png" className="hero1" alt="" />
                            <div className="image" data-cues="slideInLeft">
                                <img src="assets/images/small-images/hero1.png" className="small1" alt="" />
                            </div>
                            <div className="image" data-cues="slideInDown" data-interval="-200">
                                <img src="assets/images/small-images/hero2.png" className="small2" alt="" />
                            </div>
                            <div className="image" data-cues="slideInUp">
                                <img src="assets/images/small-images/hero3.png" className="small3" alt="" />
                            </div>
                            <div className="image" data-cues="slideInLeft">
                                <img src="assets/images/small-images/hero4.png" className="small4" alt="" />
                            </div>
                        </div>
                        <div className="responsive-image">
                            <img src="assets/images/heros/responsive1.png" className="responsive-image" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="all-shapes">
                <img src="assets/images/shapes/hero-shape1.png" className="hero-shape1" alt="" />
                <img src="assets/images/shapes/hero-shape2.png" className="hero-shape2" alt="" />
                <img src="assets/images/shapes/hero-shape3.png" className="hero-shape3" alt="" />
                <img src="assets/images/shapes/hero-shape4.png" className="hero-shape4" alt="" />
                <img src="assets/images/shapes/hero-shape5.png" className="hero-shape5" alt="" />
            </div>
        </div>
    );
}
export default About