import Copyright from "../composants/Copyright";
import Footer from "../composants/Footer";
import About from "../composants/Home/About";
import Partner from "../composants/Home/Partner";
import Projets from "../composants/Home/Projets";
import Services from "../composants/Home/Services";
import Welcome from "../composants/Home/Welcome";
import Navbar from "../composants/Navbar";
import NavbarMobile from "../composants/NavbarMobile";
import Preloader from "../composants/Preloader";

function Home()
{
    

    return  (
        <div>
            <Preloader />
            <Navbar />
            <NavbarMobile />

            <Welcome />
            <About />
            <Services />
            
            <Partner />

            <Projets />


            <Footer />
            <Copyright />
        </div>
        
    );
}

export default Home