const Team = () => {
    const arrayTeam = [
        {'name' : 'Patrice Sonan', 'role':'CEO/Co-founder','imgSrc':'assets/images/teams/team1.jpg'},
        {'name' : 'Patrick-Elysée Botchi', 'role':'CTO','imgSrc':'assets/images/teams/team2.jpg'},
        {'name' : 'Edward Germany', 'role':'Customer/Communications Manager','imgSrc':'assets/images/teams/team3.jpg'},
        {'name' : 'Edward Germany', 'role':'Web Developer','imgSrc':'assets/images/teams/team4.jpg'},
    ];
    return (
        <div class="team-warp pt-100 pb-75">
            <div class="container">
                <div class="section-title">
                    <h2>Notre équipe</h2>
                </div>
                <div class="row justify-content-center">
                    {
                        arrayTeam.map((team,index) => (
                            <div class="col-lg-3 col-md-6" >
                                <div key={index} class="team-card">
                                    <img src={team.imgSrc} class="team1" alt={team.name} />
                                    <div class="content">
                                        <h5>{team.name}</h5>
                                        <p>{team.role}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    
                   
                </div>
            </div>
        </div>
    );
}

export default Team;