const Accueil = () => {
    return (
        <div className="inner-hero-warp">
            <div className="container">
                <div className="inner-hero-content">
                    <h1>Bienvenue dans notre espace de contact</h1>
                    <p>Notre équipe attentive est là pour vous aider, écouter vos suggestions et répondre à toutes vos préoccupations. N'hésitez pas à remplir le formulaire ci-dessous avec vos coordonnées et votre message, et nous vous assurons une réponse aussi rapide que possible. Merci de choisir de rester en contact avec nous !</p>
                </div>
            </div>
        </div>
    )
}

export default Accueil