import { Route, Routes } from 'react-router-dom';
import About from './pages/About';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Projet from './pages/Projet';
import Service from './pages/Service';

function App() {
  return (
    <Routes>
      <Route path='/' exact Component={Home}></Route>
      <Route path='/index'  Component={Home}></Route>
      <Route path='/about'  Component={About}></Route>
      <Route path='/services'  Component={Service}></Route>
      <Route path='/projets'  Component={Projet}></Route>
      <Route path='/contact'  Component={Contact}></Route>
    </Routes>
  );
}

export default App;
