import React from 'react';
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
// Installez les modules Swiper
import { Link } from 'react-router-dom';

import './partner.css';


function Partner(){
    
    return (
       
        <div class="partner-features-warp pb-100">
            <div class="container-fluid style3">
                <h5 class="text-center">Nos partenaires de confiance</h5>
                <div class="inner-brand-warp">
                    <div class="swiper partner-slider">
                        <div class="swiper-wrapper">
                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            spaceBetween={20}
                            slidesPerView={5}
                            
                            pagination={{ clickable: true }}
                            scrollbar={{ draggable: true }}
                            autoplay={{ delay: 1000 }}
                            >
                            <SwiperSlide>
                                <Link to="#" class="brand-logo">
                                    <img src="assets/images/logo/brand1.png" alt="" />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link to="#" class="brand-logo">
                                    <img src="assets/images/logo/brand2.png" alt="" />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link to="#" class="brand-logo">
                                    <img src="assets/images/logo/brand3.png" alt="" />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link to="#" class="brand-logo">
                                    <img src="assets/images/logo/brand4.png" alt="" />
                                </Link>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Link to="#" class="brand-logo">
                                    <img src="assets/images/logo/brand5.png" alt="" />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link to="#" class="brand-logo">
                                    <img src="assets/images/logo/brand6.png" alt="" />
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link to="#" class="brand-logo">
                                    <img src="assets/images/logo/brand1.png" alt="" />
                                </Link>
                            </SwiperSlide>

                            {/* Ajoutez autant de slides que nécessaire */}
                        </Swiper>
    
                            
                            
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Partner