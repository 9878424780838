import { Link } from "react-router-dom";

function NavbarMobile()
{
    return (
        <div className="responsive-navbar offcanvas offcanvas-end" tabIndex="-1" id="navbarOffcanvas">
            <div className="offcanvas-header">
                <Link to="index.html" className="logo d-inline-block">
                    <img src="assets/images/logo-csi-134x57.png" alt="logo" />
                </Link>
                <button type="button" className="close-btn" data-bs-dismiss="offcanvas" aria-label="Close">
                    <i className="ri-close-line"></i>
                </button>
            </div>
            <div className="offcanvas-body">
                <div className="accordion" id="navbarAccordion">
                    <div className="accordion-item">
                        <Link to="index.html" className="accordion-link active">
                            Accueil
                        </Link>
                        
                    </div>
                    <div className="accordion-item">
                        <Link className="accordion-link" to="about.html">
                            A propos de nous
                        </Link>
                    </div>

                    <div className="accordion-item">
                        <Link to="services.html" className="accordion-link">
                            Services
                        </Link>
                        
                    </div>

                    <div className="accordion-item">
                        <Link to="portfolio.html" className="accordion-link">
                            Projets 
                        </Link>
                    </div>

                    
                    <div className="accordion-item">
                        <Link className="accordion-button without-icon" to="contact.html">
                            Contactez Nous
                        </Link>
                    </div>
                </div>
                <div className="others-option d-flex align-items-center">
                    <div className="option-item">
                        <Link to="contact.html" className="default-btn home-one-main">Demander un dévis</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NavbarMobile