function Storie()
{
    return (
        <div class="about-hero-warp">
            <div class="container">
                <div class="about-hero-content">
                    <h1>Qui sommes-nous ?</h1>
                    <p>Fondée avec une passion pour l'excellence technologique, La Compagnie de solutions Informatiques (CSI) est une entreprise dynamique spécialisée dans la fourniture de solutions informatiques intégrées. Forts d'une équipe talentueuse d'experts en informatique, nous sommes déterminés à apporter une valeur ajoutée à votre entreprise grâce à notre expertise approfondie.
</p>
                </div>
            </div>
        </div>
    );
}

export default Storie;