import Copyright from "../composants/Copyright";
import Footer from "../composants/Footer";
import Projets from "../composants/Home/Projets";
import Navbar from "../composants/Navbar";
import NavbarMobile from "../composants/NavbarMobile";
import Preloader from "../composants/Preloader";
import Activites from "../composants/Service/Activites";
import Presentation from "../composants/Service/Presentation";
import Testimony from "../composants/Service/Testimony";

function Service()
{
    return (
        <div>
            <Preloader></Preloader>

            <Navbar />
            <NavbarMobile />
            <Presentation/>

            <Activites />

            <Testimony />
            <Projets />
            <Footer />
            <Copyright />
        </div>
    );
}

export default Service