import Image from "../composants/About/Image";
import Quality from "../composants/About/Quality";
import Skills from "../composants/About/Skills";
import Storie from "../composants/About/Storie";
import Team from "../composants/About/Team";
import Copyright from "../composants/Copyright";
import Footer from "../composants/Footer";
import Projets from "../composants/Home/Projets";
import Navbar from "../composants/Navbar";
import NavbarMobile from "../composants/NavbarMobile";
import Preloader from "../composants/Preloader";

function About()
{
    return (
        <div>
            <Preloader />
            <Navbar />
            <NavbarMobile />
            <Storie />
            <Image />
            <Skills />
            <Quality />
            <Team />

            <Projets />
            <Footer />
            <Copyright />
        </div>
    );
}

export default About;