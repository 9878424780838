const Presentation = () => {
    return (
        <div class="inner-hero-warp">
            <div class="container">
                <div class="inner-hero-content style2">
                    <h1>Propulsez votre entreprise vers de nouveaux sommets</h1>
                    <p>La Compagnie de Solutions Informatiques (CSI) propose une gamme complète de services informatiques adaptés à vos besoins.</p>
                </div>
            </div>
        </div>
    );
}

export default Presentation