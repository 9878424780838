import axios from 'axios'
import React, { useState } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const ContactForm = () => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [sujet, setSujet] = useState("")
    const [messages, setMessages] = useState("")
    const [contact, setContact] = useState("")


    const handleSubmit = (e) => {
        e.preventDefault()

        if(name  && email && sujet && contact && messages){
            const text = `Nom: ${name}\nContact:${contact}\nMessage:${messages}`
            axios.post('https://compagnie-de-solutions-informatiques.com/send_email',{
                email,
                sujet,
                text
            }).then(()=> {
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.onmouseenter = Swal.stopTimer;
                      toast.onmouseleave = Swal.resumeTimer;
                    }
                });
                  Toast.fire({
                    icon: "success",
                    title: "Message envoyé avec succès"
                });
            }
                // alert("Message envoyé avec succès")
                
            )
            .catch(() =>{
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.onmouseenter = Swal.stopTimer;
                      toast.onmouseleave = Swal.resumeTimer;
                    }
                  });
                  Toast.fire({
                    icon: "error",
                    title: "Erreur rencontrée. Contacter le support technique"
                  });
            })
            return;
        }

        return Swal.fire({
            icon: "error",
            title: "Champs Obligatoires...",
            text: "Veuillez renseigner tous les champs avant de soumettre le formulaire svp",
            // footer: '<a href="#">Why do I have this issue?</a>'
          });
        
        // alert("Veuillez renseigner tous les champs avant de soumettre le formulaire svp")
    }

    return (
        <div className="contact-warp ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="contact-content single-section">
                            <span className="features-title">Nous contacter</span>
                            <h2>Vous avez un besoin particulier/une suggesstion ?</h2>
                            <p>Ecrivez nous ou Appelez nous</p>
                            <div className="contact-widget d-flex align-items-center ">
                                <div className="icon-style">
                                    <div className="icon">
                                        <img src="assets/images/svgs/contact1.svg" className="contact1" alt="" />
                                    </div>
                                </div>
                                <div className="content">
                                    <h5>Par email</h5>
                                    <a href="mailto:contact@compagnie-de-solutions-informatiques.com">contact@compagnie-de-solutions-informatiques.com</a>
                                </div>
                            </div>
                            <div className="contact-widget d-flex align-items-center ">
                                <div className="icon-style">
                                    <div className="icon">
                                        <img src="assets/images/svgs/contact2.svg" className="contact1" alt="" />
                                    </div>
                                </div>
                                <div className="content">
                                    <h5>Appelez nous</h5>
                                    <a href="tel:55036266727">+225 0757579607 / 0765510755</a>
                                </div>
                            </div>
                            {/* <div className="contact-widget d-flex align-items-center ">
                                <div className="icon-style">
                                    <div className="icon">
                                        <img src="assets/images/svgs/contact3.svg" className="contact1" alt="" />
                                    </div>
                                </div>
                                <div className="content">
                                    <h5>Connect With Locations</h5>
                                    <span>711-2880 Nulla St. Mankato Mississippi</span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="massage-warp">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    
                                    <div className="col-lg-12">
                                        <div className="input-box">
                                            {/* <label for="exampleFormControlInput1" className="form-label">Nom complet/Société<span className="text-danger">*</span></label> */}
                                            <input type="text" onChange={(e) => setName(e.target.value)} className="form-control" id="name" placeholder="Nom complet/Société *"  />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="input-box">
                                            {/* <label for="exampleFormControlInput1" className="form-label">Email<span className="text-danger">*</span></label> */}
                                            <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" id="email" placeholder="Email *" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="input-box">
                                            {/* <label for="" className="form-label">Sujet<span className="text-danger">*</span></label> */}
                                            <input type="text" onChange={(e) => setSujet(e.target.value)} className="form-control" id="sujet" placeholder="Votre sujet " />
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-6">
                                        <div className="input-box">
                                            {/* <label for="exampleFormControlInput1" className="form-label">Contact<span className="text-danger">*</span></label> */}
                                            <input type="text" onChange={(e) => setContact(e.target.value)} className="form-control" id="contact" placeholder="Contact *" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="input-box">
                                            {/* <label for="exampleFormControlInput1" className="form-label">Votre Message<span className="text-danger">*</span> </label> */}
                                            <textarea type="text" onChange={(e) => setMessages(e.target.value)} className="form-control" placeholder="Tapez votre message ici....... *"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <button className="default-btn heading-color" type="submit">Envoyer</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContactForm