const Quality = () => {
    return (
        <div class="about-help-warp">
            <div class="container">
                <div class="inner-about-warp ptb-100">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="about-content">
                                <h3>Notre Engagement envers la Qualité et l'Innovation</h3>
                                <p>
                                    <ul>
                                        <li>
                                        Expertise approfondie, savoir-faire technologique et le développement de marque.
                                        </li>
                                        <li>
                                            Engagement envers la satisfaction client
                                        </li>
                                        <li>
                                            Solutions sur mesure adaptées à vos besoins spécifiques
                                        </li>
                                        <li>
                                        Approche proactive pour rester à la pointe de l'innovation
                                        </li>
                                    </ul>
                                </p>
                                
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="about-image">
                                <img src="assets/images/heros/about2.jpg" class="about2" alt="about" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Quality