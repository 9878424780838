import { Link } from 'react-router-dom';

function Navbar(){
    return (
        <div>
            <nav className="navbar navbar-expand-lg top-navbar style2" id="navbar">
            <div className="container-fluid inner-navbar-warp">
                <Link className="navbar-brand" to="/index">
                    <img src="assets/images/logo-csi-134x57.png" className="navbar-logo" alt="logo" />
                </Link>
                <Link className="navbar-toggler" data-bs-toggle="offcanvas" to="#navbarOffcanvas" role="button" aria-controls="navbarOffcanvas">
                    <span className="burger-menu">
                        <span className="top-bar"></span>
                        <span className="middle-bar"></span>
                        <span className="bottom-bar"></span>
                    </span>
                </Link>
                <div className="collapse navbar-collapse">
                    <ul className="navbar-nav mx-auto style2">

                        <li className="nav-item">
                            <Link to="/index" className="nav-link active">
                                Accueil
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link to="/about" className=" nav-link">
                                A propos de nous
                            </Link>
                            
                        </li>

                        <li className="nav-item ">
                            <Link to="/services" className=" nav-link">
                                Services
                            </Link>
                            
                        </li>

                        <li className="nav-item ">
                            <Link to="/projets" className=" nav-link">
                                Projets
                            </Link>
                        </li>
                        
                        {/* <li className="nav-item">
                            <a to="javascript:void(0)" className="dropdown-toggle nav-link">
                                Blog
                            </a>
                            <ul className="dropdown-menu">
                                <li className="nav-item">
                                    <a to="blog" className="nav-link">Blog</a>
                                </li>
                                <li className="nav-item">
                                    <a to="blog-details" className="nav-link">Blog Details</a>
                                </li>
                            </ul>
                        </li> */}
                        <li className="nav-item">
                            <Link to="/contact" className="nav-link ">Contactez
                                <span>Nous</span> </Link>
                        </li>
                    </ul>
                        
                    <div className="others-option d-flex align-items-center">
                        <div className="option-item">
                            <Link to="/contact" className="default-btn heading-color">Demander un devis</Link>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        </div>
    );
}

export default Navbar;