import { useEffect } from "react";

function ScrollToTopBtn(){
     // Select the button element Js
     
     useEffect(()=>{
         // Fonction qui sera exécutée lors du scroll
         const handleScroll = () => {
            
            const scrollTopBtn = document.getElementById("scrollTopBtn");
            // Vérifier la position de défilement
            if(document.body.scroll > 20 || document.documentElement.scrollTop > 20){
                scrollTopBtn.style.display = "block"
            }else{
                scrollTopBtn.style.display = "none"
            }


        }

        const onclickToScrollBtn = () => {
            const scrollTopBtn = document.getElementById("scrollTopBtn");


            scrollTopBtn.addEventListener('click', () => {
                // Code à exécuter lors du clic sur le bouton
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth"
                });
              });
            
        }
        
        // Ajouter un écouteur d'événement sur le scroll
        window.addEventListener("scroll", handleScroll);

        // Si on clique sur le btn
        window.addEventListener('click',onclickToScrollBtn)

        // Nettoyage de l'effet (cleanup) lors du démontage du composant
        return () => {
            window.removeEventListener("scroll",handleScroll)
            window.removeEventListener("click",onclickToScrollBtn)
        }
     },[]);

    

    // Show the button when the user scrolls down Js
   
    return (
        <button id="scrollTopBtn" style={{ display:'none' }}>
            <i className="ri-arrow-up-circle-fill"></i>
        </button>
    );
}

export default ScrollToTopBtn