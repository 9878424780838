const Activites = () => {
    const listWorks = [
        {'name':'Conseil en Informatique','description':'Analyse approfondie de vos besoins, recommandations stratégiques et planification pour une infrastructure informatique optimale','icon':'flaticon-customize'},
        {'name':'Développement d\'applications web/mobile','description':' Création de solutions logicielles sur mesure pour automatiser vos processus métier et améliorer l\'efficacité opérationnelle','icon':'flaticon-customize'},
        {'name':'Infrastructure IT','description':'Conception, mise en place et maintenance de systèmes informatiques robustes et sécurisés, adaptés à la croissance de votre entreprise.','icon':'flaticon-customize'},
        {'name':'Assistance technique','description':'Un support technique rapide et fiable pour résoudre vos problèmes informatiques, assurant une continuité opérationnelle sans faille.','icon':'flaticon-customize'},
        {'name':'Conception/Administration de Site web','description':'La création et l\'administration de sites web pour une entreprise sont des services qui englobent plusieurs aspects pour assurer une présence en ligne efficace et professionnelle','icon':'flaticon-customize'},
        {'name':'Intégration de solutions','description':'Mettre en place des systèmes logiciels complets qui permettent une gestion efficace et intégrée des différentes fonctions d\'une entreprise.','icon':'flaticon-customize'},
        {'name':'Sécurité informatique','description':'Protégez vos données sensibles avec nos solutions de sécurité informatique avancées, garantissant la confidentialité et l\'intégrité de vos informations.','icon':'flaticon-customize'},
        {'name':'UI/UX design','description':'Analyse approfondie de vos besoins, recommandations stratégiques et planification pour une infrastructure informatique optimale','icon':'flaticon-customize'},
        {'name':'Maintenance informatique et électronique','description':'Assurer le bon fonctionnement, la sécurité et la durabilité des équipements informatiques et électroniques de votre organisation','icon':'flaticon-customize'},
        {'name':'Solution énergetique','description':'Ces solutions peuvent englober diverses sources d\'énergie, technologies et pratiques pour promouvoir la transition vers un système énergétique plus propre et plus durable','icon':'flaticon-customize'},
        {'name':'Conception électronique','description':'Analyse approfondie de vos besoins, recommandations stratégiques et planification pour une infrastructure informatique optimale','icon':'flaticon-customize'},
        {'name':'Communication/Marketing digital','description':'Promouvoir votre marque, votre produit ou votre service en utilisant des canaux numériques','icon':'flaticon-customize'},
        {'name':'Développement de marque','description':' Création et renforcement de votre identité de marque, du logo à la stratégie de communication, pour vous démarquer dans un monde numérique compétitif.','icon':'flaticon-customize'},
        {'name':'Création graphique/Montage','description':'Vous offrir une expertise artistique et technique pour la conception visuelle et la réalisation de contenus visuels attrayants','icon':'flaticon-customize'},
    ]
    return (
        <div class="service-warp pt-100 pb-75">
            <div class="container">
                <div class="section-title">
                    <span class="features-title">Nos services</span>
                    <h2>Une gamme complète repondant à tous vos besoins.</h2>
                </div>
                <div class="row justify-content-center">

                    {
                        listWorks.map( (service, index) => (
                            <div class="col-lg-3 col-sm-6">
                                <div key={index} class="business-card">
                                    <div class="icon">
                                        <i class={service.icon}></i>
                                    </div>
                                    <div class="content">
                                        <h3>{service.name}</h3>
                                        <p>{service.description},</p>
                                    </div>
                                </div>
                            </div>
                        ))
                        
                    }
                    
                </div>
            </div>
        </div>
    );
}

export default Activites