import { Link } from "react-router-dom";
function Services()
{
    const listWorks = [
        {'name':'Site web et applications mobile/web','description':'Création de solutions logicielles sur mesure pour automatiser vos processus métier et améliorer l\'efficacité opérationnelle.','icon':'flaticon-credit-card', 'url':'services.html'},
        {'name':'Conseils en Informatique','description':'Analyse approfondie de vos besoins, recommandations stratégiques et planification pour une infrastructure informatique optimale','icon':'flaticon-tap', 'url':'services.html'},
        {'name':'Infrastructure IT','description':'Conception, mise en place et maintenance de systèmes informatiques robustes et sécurisés, adaptés à la croissance de votre entreprise','icon':'flaticon-secure-payment', 'url':'services.html'},
        {'name':'Sécurité informatique','description':'Création de solutions logicielles sur mesure pour automatiser vos processus métier et améliorer l\'efficacité opérationnelle.','icon':'flaticon-credit-card', 'url':'services.html'},
        {'name':'Assistance technique','description':'Création de solutions logicielles sur mesure pour automatiser vos processus métier et améliorer l\'efficacité opérationnelle.','icon':'flaticon-report', 'url':'services.html'},
        {'name':'Dévéloppement de marque','description':'Création de solutions logicielles sur mesure pour automatiser vos processus métier et améliorer l\'efficacité opérationnelle.','icon':'flaticon-mobile-banking', 'url':'services.html'},
        {'name':'Maintenance informatique et électronique','description':'Services de maintenance préventive et corrective pour assurer la performance durable de vos équipements','icon':'flaticon-customize', 'url':'services.html'},
        {'name':'Solution énergetique et conception électronique','description':'Conception et mise en œuvre de solutions énergétiques durables. Services complets de conception électronique ','icon':'flaticon-money', 'url':'services.html'},
    ]
    return (
        <div className="features-warp pt-100 pb-75 ">
            <div className="container">
                <div className="section-title style6">
                    <span className="title">Services</span>
                    <h2>La CSI propose une gamme complète de services informatiques adaptés à vos besoins !</h2>
                </div>
                <div className="row justify-content-center">
                    {
                        listWorks.map((service, index) => (
                            <div className="col-lg-3 col-sm-6" >
                                <div key={index} className="features-box">
                                    <div className="icon bg1">
                                        <i className={service.icon}></i>
                                    </div>
                                    <h3>
                                        <Link href={service.url}>{service.name}</Link>
                                    </h3>
                                    <p>{service.description}</p>
                                </div>
                            </div>
                        ))
                    }
                    
                    
                </div>
            </div>
        </div>
    );
}

export default Services