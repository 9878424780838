import { useEffect, useState } from "react";

function Preloader(){
    const [visible, setVisible] = useState(true)
    const imgLogoStyle = {
        width: '250px',
        height:'67px',
        bottomColor:'0 0 10px rgba(0, 0, 0, 0.1)'
    }
    useEffect(()=> {
        const timeOutId = setTimeout(()=>setVisible(false), 3000)
        
        return () => clearTimeout(timeOutId)
    })

    return visible ? (
        <div className="preloader-area position-fixed text-center bg3" id="preloader">
            <div className="cube-wrapper">
                <div className="cube-folding">
                  <span className="leaf1"></span>
                  <span className="leaf2"></span>
                  <span className="leaf3"></span>
                  <span className="leaf4"></span>
                </div>
            <span className="loading" data-name="Loading">
                <img src="assets/images/logo-csi.png" style={imgLogoStyle}></img>
            </span>
            </div>
        </div>
    ) : null ;
}

export default Preloader