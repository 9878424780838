
const RenderStar = (props) =>{
    const afficheStar = () => {
        const iconstar = '<i class="ri-star-fill"></i>'
        for(let i=0;i<props.note;props.note++){
            iconstar = '<i class="ri-star-fill"></i>'
            // iconstar.html('<i class="ri-star-fill"></i>')
            document.write(iconstar);
        }
    }
    
    return (
       <div>
        {afficheStar}
       </div>
    )
}

export default RenderStar