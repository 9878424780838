import RenderStar from "./RenderStar";

const Testimony = () => {
    const testimonies = [
        {'name':'Botchi Dagou','role':'Directeur Général','description':'Je tiens à exprimer ma satisfaction totale à l\'égard de l\'équipe qui a travaillé sur la création de notre site web. Nous avons choisi la CSI pour ce projet, et nous ne pouvions pas être plus satisfaits du résultat final', 'notes':'5','imgSrc':'assets/images/users/benefit1.png'},
        {'name':'Lorem Ipsum','role':'Gérant','description':'Ce qui a vraiment distingué la CSI, c\'est leur créativité et leur capacité à traduire nos idées en un design exceptionnel. Leur équipe de conception a su capturer l\'essence de notre entreprise tout en apportant une touche moderne et esthétique à notre site', 'notes':'4','imgSrc':'assets/images/users/benefit1.png'},
    ]
    return (
        <div class="benefit-warp pb-75">
            <div class="container">
                <div class="section-title">
                    <h2>Ce que quelqu'un dit pour communiquer sur notre prestation </h2>
                </div>
                <div class="row">
                    {
                        testimonies.map((testimony, index) => (
                            <div class="col-lg-6">
                                <div class="benefit-card d-flex align-items-center ">
                                    <div class="content">
                                        <p>“{testimony.description}”</p>

                                        <div class="name-review d-flex align-items-center">
                                            <div class="name">
                                                <h6>{testimony.name}</h6>
                                                <span>{testimony.role}</span>
                                            </div>
                                            <div class="star">
                                                
                                                <RenderStar note={testimony.notes} />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="benefit-image">
                                        <div class="icon">
                                            <img src="assets/images/svgs/benefit1.svg" class="benefit1" alt="benefit" />
                                        </div>
                                        <div class="image">
                                            <img src={testimony.imgSrc} class="benefit1" alt="benefit" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    
                   
                </div>
            </div>
        </div>
    );
}

export default Testimony