import { Link } from "react-router-dom";

function Copyright(){
    const currentYear = new Date().getFullYear()

    return (
        <div className="copyright-warp">
            <div className="container">
                <div className="inner-copyright-warp">
                    <p>© {currentYear} <Link to="https://compagnie-de-solutions-informatiques.com/" rel="noopener">Compagnie de Solutions Informatiques</Link></p>
                </div>
            </div>
        </div>
    );
}

export default Copyright