import { Link } from "react-router-dom";

function Welcome()
{
    return (
        <div className="middle-hero-warp">
            <div className="container">
                <div className="middle-hero-content">
                    <h2>Bienvenue chez CSI, La Compagnie de Solutions Informatiques - Votre Partenaire Informatique de Confiance</h2>
                    <p>
                    Nous comprenons que le succès dans le monde des affaires d'aujourd'hui dépend étroitement de la technologie qui le soutient. C'est pourquoi nous sommes fiers d'être vous accompagner en vous offrant des solutions innovantes pour répondre à vos besoins spécifiques
                    </p>
                    <div className="middle-benner-btn">
                        <Link to="/contact" className="default-btn home-three-main">Nous contacter</Link>
                        <Link to="/services" className="default-btn style3">En savoir plus</Link>
                    </div>
                </div>
            </div>
            <img src="assets/images/shapes/hero3-shape1.png" className="hero3-shape1"  alt=""/>
            <img src="assets/images/shapes/hero3-shape2.png" className="hero3-shape2"  alt=""/>
            
        </div>
    );
}

export default Welcome