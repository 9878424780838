import { Link } from "react-router-dom";
import ScrollToTopBtn from "./ScrollToTopBtn";
function Footer(){
    const logoStyle = {
        width:'160px',
        height:'100px'
    }
    return (
        <div className="footer-warp pt-100 pb-75 bg-f9f2f3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-sm-6" >
                        <div className="footer-widget">
                            <h4>Notre Entreprise</h4>
                            <p>Nous restons à la pointe des avancées technologiques pour vous offrir des solutions qui anticipent vos besoins futurs tout en résolvant vos défis actuels..</p>
                            <Link to="/index" className="footer-logo">
                                <img src="assets/images/logo-csi.png" alt="logo" style={logoStyle}/>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6" >
                        <div className="footer-widget">
                            <h4>Liens Utiles</h4>
                            <ul className="footer-link">
                                <li>
                                    <Link to="/index">Accueil</Link>
                                </li>
                                <li>
                                    <Link to="/services">Services </Link>
                                </li>
                                <li>
                                    <Link to="/about">A propos de nous</Link>
                                </li>
                                <li>
                                    <Link to="/projets">Réalisations</Link>
                                </li>
                                <li>
                                    <Link to="/contact">Contactez nous</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6" >
                        <div className="footer-widget">
                            <h4>Nous contacter</h4>
                            <ul className="footer-contact">
                                <li>
                                    <span>Service client</span>
                                    <Link to="tel:18882345-6789">+225 0757579607/0765510755</Link>
                                </li>
                                <li>
                                    <span>Besoin d'assistance ?</span>
                                    <Link to="mailto:contact@compagnie-de-solutions-informatiques.com">contact@compagnie-de-solutions-informatiques.com</Link>
                                </li>
                                <li>
                                    <span>Adresse</span>
                                    <p>Abidjan </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6" >
                        <div className="footer-widget">
                            <h4>Boîte aux lettres</h4>
                            <form>
                                <div className="input-box">
                                    <input type="text" className="form-control" placeholder="Adresse email" />
                                    <button className="input-btn" type="submit">
                                        <img src="assets/images/svgs/paper-plane.svg" alt="paper-plane" />
                                    </button>
                                </div>
                            </form>
                            <ul className="social-list">
                                <li>
                                    <Link to="https://www.facebook.com/profile.php?id=61555069029639&mibextid=gik2fB " target="_blank"><i className="ri-facebook-circle-line"></i></Link>
                                </li>
                                <li>
                                    <Link to="https://www.linkedin.com/company/compagnie-de-solutions-informatiques/" target="_blank"><i className="ri-linkedin-line"></i></Link>
                                </li>
                                <li>
                                    <Link to="https://www.instagram.com/csi2.25/?igsh=MWVuNXY0MXY0M2JsYg%3D%3D" target="_blank"><i className="ri-instagram-line"></i></Link>
                                </li>
                                
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="top-button-icon">
                <ScrollToTopBtn />
            </div>
        </div>
    );
}

export default Footer