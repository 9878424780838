import Accueil from "../composants/Contact/Accueil";
import ContactForm from "../composants/Contact/ContactForm";
import Copyright from "../composants/Copyright";
import Footer from "../composants/Footer";
import Navbar from "../composants/Navbar";
import NavbarMobile from "../composants/NavbarMobile";
import Preloader from "../composants/Preloader";

const Contact = () => {
    return (
        <div>
            <Preloader />
            <Navbar />
            <NavbarMobile />

            <Accueil />
            <ContactForm />
            <Footer />
            <Copyright />
        </div>
    );
}

export default Contact