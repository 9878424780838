import { Link } from "react-router-dom";
function Skills()
{
    
    return (
        <div class="about-boost-warp pt-100 pb-75">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-6 col-md-6" >
                        <div class="boost-card colur1">
                            <div class="icon-content d-flex align-items-center">
                                <div class="icon">
                                    <div class="rounded-icon">
                                        <i class="flaticon-strategy"></i>
                                    </div>
                                </div>
                                <h3>
                                    <Link to="service-details.html">Nos valeurs</Link>
                                </h3>
                            </div>
                            <p>Nous ne sommes pas seulement des experts en informatique, nous sommes également des architectes numériques passionnés par la création de marques fortes.</p>
                                <Link to="services.html" class="hero-service-btn">
                                    <i class="flaticon-trend"></i>
                                    Explorer
                                </Link>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6" >
                        <div class="boost-card colur2">
                            <div class="icon-content d-flex align-items-center">
                                <div class="icon">
                                    <div class="rounded-icon">
                                        <i class="flaticon-coding"></i>
                                    </div>
                                </div>
                                <h3>
                                    <Link to="service-details.html">Notre Mission</Link>
                                </h3>
                            </div>
                            <p>En tant que votre partenaire informatique de confiance et créateur de marque numérique, nous sommes déterminés à propulser votre entreprise vers de nouveaux sommets grâce à une fusion stratégique de technologie et d'identité de marque.</p>
                                <Link to="services.html" class="hero-service-btn">
                                    <i class="flaticon-trend"></i>
                                    Explorer
                                </Link>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default Skills;